.login {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login__form {
  //height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login__element {
  margin: 5px;
}