.transactionRow {
  display: flex;
  flex-direction: row;
  padding-top: 5px;
  padding-bottom: 5px;

  &:hover {
    background-color: transparentize($white, 0.95);
  }
}

.transactionRow__child div {
  color: lightslategray;

  &::selection {
    color: lightgray;
  }
}

.transactionRow__child__light div {
  color: black;
  font-style: italic;
}

.transactionRow__parent__light div {
  font-weight: 500;
}

.transactionTitle {
  //background-color: $pt-dark-app-background-color;
  padding-top: 5px;
  padding-right: 10px;
  padding-left: 10px;
}