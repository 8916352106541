* {
  box-sizing: border-box;
}

html {
  //font-size: 62.5%;
  height: 100%;
}

body {
  //color: $dark-grey;
  //font-family: Helvetica, Arial, sans-serif;
  //font-size: $m-size;
  //line-height: 1.6;
  height: 100%;
  //noinspection CssInvalidPropertyValue
  overflow: overlay;
  padding-right: 15px;
  //background-color: $pt-dark-app-background-color;
}

#root {
  height: 100%;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
}

.highlight-hover {
  &:hover {
    background-color: transparentize($white, 0.95);
  }
}

//::-webkit-scrollbar {
//  width: 1.1rem;
//  //cursor:pointer;
//}
//
//::-webkit-scrollbar-track {
//  box-shadow: inset 0 0 6px 6px rgba(0,0,0,0.2);
//  //background-color: rgba(0, 0, 0, 0.2);
//  border: 2px solid transparent;
//  border-radius: 6px;
//}
//
//::-webkit-scrollbar-thumb {
//  box-shadow: inset 0 0 6px 6px rgba(180,180,180,0.5);
//  //background-color: rgba(120,120,120,.5);
//  //outline: 1px solid grey;
//  border-radius: 6px;
//  //border: 1px solid rgba(0,0,0,0.6);
//  border: 2px solid transparent;
//}

//button {
//  cursor: pointer;
//}
//
//button:disabled {
//  cursor: default;
//}
//
//.is-active {
//  font-weight: bold;
//}