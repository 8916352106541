.header {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 5px;
  //background-color: $dark-gray5;
}

.header__left {
  width: 50%;
  display: flex;
  align-items: center;
}

.header__right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header__option {
  background-color: transparent;
  color: #48aff0;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  padding: 10px;

  &:focus {
    outline: 0;
  }
}